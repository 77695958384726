import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerItems: {
    display: "flex",
    flexWrap: "wrap",
    gap: "3rem",
  },
}));
export default useStyles;

import React, { useCallback, useContext, useEffect, useState } from "react";
import { navigate, PageProps } from "gatsby";
import { LayoutOnBoarding, SEO } from "@components";
import { LoadingContext } from "@context";
import { getAuth } from "firebase/auth";
import LayoutServices from "@components/Services/Layout/LayoutServices";
import Credits from "@components/Services/Credits/Credits/Credits";
import { Route } from "@interfaces";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Investments from "@components/Services/Investments/Investments";
import Notification24 from "@components/Services/Credits/Credits/Credits/Searching/Notification24hs/Notification24";
import Notification48 from "@components/Services/Credits/Credits/Credits/Searching/Notification48hs/Notification48";
import Notification from "@components/Services/Credits/Credits/Credits/Searching/Notification/Notification";
import { IconButton, makeStyles, useMediaQuery } from "@material-ui/core";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { useAuth } from "@hooks";

const SaveByRokinPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });

  const classes = useStyles();
  const { hideLoader } = useContext(LoadingContext);
  const currentUser = getAuth().currentUser;
  const [finishSave, setFinishSave] = useState({
    Notification48hs: false,
    Notification24hs: false,
    Notification: false || undefined,
  });
  //accidentes, automotriz atc..

  //new state
  const [creditCategory, setCreditCategory] = useState(-1);
  const [pathRoute, setPathRoute] = useState<JSX.Element>();

  useEffect(() => {
    hideLoader("");
  }, [currentUser]);

  const SelectTypeCredit = useCallback(
    (type: number) => {
      console.log(type);
      // 0 nuevo credito - 1 refinanciar - 2 consolidar
      setCreditCategory(type);
    },
    [creditCategory]
  );
  const isMobile = useMediaQuery("(max-width: 1200px)");
  const onBackClick = () => {
    creditCategory === -1 && navigate(Route.services);
    creditCategory === 0 && setCreditCategory(-1);
    creditCategory === 1 && setCreditCategory(-1);
    creditCategory === 2 && setCreditCategory(-1);
    setPathRoute(<></>);
  };
  console.log(creditCategory);
  /* <>
      <ArrowForwardIosIcon />
      <span>CRÉDITOS</span>
    </>
     */
  return (
    <>
      <LayoutOnBoarding
        className={classes.layoutOnBoarding}
        hiddenHeader={isMobile}
        activeServices
      >
        <SEO />

        {finishSave.Notification48hs && <Notification48 />}
        {finishSave.Notification24hs && <Notification24 />}
        {finishSave.Notification && <Notification />}

        {!finishSave.Notification48hs &&
          !finishSave.Notification &&
          !finishSave.Notification24hs && (
            <LayoutServices
              state={pathRoute}
              title={"INVERSIONES"}
              onBackClick={onBackClick}
              key="Bottom"
              activeServices
            >
              <Investments
                creditCategory={creditCategory}
                setCreditCategory={setCreditCategory}
                setPathRoute={setPathRoute}
                setFinishSave={setFinishSave}
              />
            </LayoutServices>
          )}
        {isMobile && (
          <LayoutMobileInvestments activeServices>
            <></>
          </LayoutMobileInvestments>
        )}
      </LayoutOnBoarding>
    </>
  );
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({
  layoutOnBoarding: {
    background:
      "linear-gradient(151deg, rgb(10, 86, 105) 6%, rgb(0, 169, 194) 203%)",
    minHeight: "100vh",
    fontFamily: "Montserrat",
  },
}));

import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 5rem",
    [theme.breakpoints.down(1280)]: {
      width: "100%",
      height: "50rem",
      paddingTop: "5rem",
    },
  },
  title: {
    paddingBottom: "1.5rem",
    color: theme.palette.text.secondary,
    fontWeight: 300,
  },
  containerList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "2rem 4rem",

    "& .input": {
      width: "12.5rem",
      display: "flex",
      flexDirection: "column",
    },
    "& .select": {
      width: "12rem",
      display: "flex",
      flexDirection: "column",
    },
    "& .containerInput": {
      display: "flex",
      flexDirection: "column",
    },
  },
  button: {
    marginTop: "0rem",
    display: "flex",
    justifyContent: "center",
  },
  buttonPrimary: {
    width: "18rem",
    marginTop: "2rem",
  },
  selectorContainer: {
    display: "flex",
    justifyContent: "center",
  },
  InvestmentProfilebutton: {
    display: "flex",
    justifyContent: "center",
    "& span": {
      fontFamily: theme.typography.fontFamily,
      fontSize: " 0.75rem",
      color: "#7D7C7C",
      textDecoration: "underline",
      textDecorationColor: "#D3D3D3",
      cursor: "pointer",
    },
  },
}));
export default useStyles;

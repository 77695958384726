import { ApolloClient, gql } from "@apollo/client";

const SEND_CONTACT_FORM_MUTATION = gql`
  mutation CreateContactFromService($data: CreateContactFormServiceParams!) {
    createContactFormService(data: $data)
  }
`;

type CreateUserParams = {
  data: {
    name: string;
    email: string;
    data: JSON;
  };
};

type CreateUserResult = {
  createContactForm: boolean;
};

export async function sendContactFormService(
  payload: CreateUserParams["data"],
  client: ApolloClient<object>
): Promise<boolean> {
  const result = await client.mutate<CreateUserResult, CreateUserParams>({
    mutation: SEND_CONTACT_FORM_MUTATION,
    variables: { data: payload },
  });
  return !!result.data?.createContactForm;
}

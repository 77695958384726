import useGeneralStyles from "@components/Services/styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import React, { useCallback, useContext, useState } from "react";
import useStyles from "./JustInvest.style";
import { navigate } from "gatsby";
import { useForm } from "@hooks";
import { Route } from "interfaces/routes";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { PERSON_ICON } from "images/AWS/productTour";
import Notification24 from "@components/Services/Credits/Credits/Credits/Searching/Notification24hs/Notification24";
import HeaderServices from "@components/Services/Layout/Header/Header";
import { useCurrentUser } from "@apollo";
import { useApolloClient } from "@apollo/client";
import { LoadingContext } from "@context";
import { useMediaQuery } from "@material-ui/core";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface creditsProps {
  setCreditCategory: (value: number) => void;
  creditCategory: number;
  setPathRoute: (value: JSX.Element) => void;
  setFinishSave: (value: any) => void;
}

const JustInvest: React.FC<creditsProps> = ({
  setCreditCategory,
  creditCategory,
  setFinishSave,
  setPathRoute,
}) => {
  const classes = useStyles();
  const [form] = useForm();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();

  const onSend = useCallback(() => {
    form.investmentProfile = {
      quiero: "Analizar opciones de inversión para solo invertir ",
    };
    setFinishSave({
      Notification48hs: false,
      Notification24hs: true,
      Notification: false,
    });

    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.investmentProfile;

    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form]);
  return (
    <>
      <HeaderServices
        title="INVERSIONES"
        onBackClick={() => setCreditCategory(-1)}
        steps={["Cómo invertir"]}
      />
      <div className={classes.container}>
        <section className={classes.step1Container}>
          <p className={classes.upperLeftParragraph}>
            Para esto, lo aconsejable es saber el{" "}
            <strong>riesgo que estás dispuesto a correr</strong> y conocer tu
            perfil de inversión.
          </p>

          <div
            onClick={() => navigate(Route.toolsSavingAndInvestMission1)}
            className={classes.buttonContainer}
          >
            <img src={PERSON_ICON} alt="" />
            <div>
              <span className={classes.buttonTitle}>Hacer el test</span>
            </div>
            <div>{<ArrowForwardIosIcon />}</div>
          </div>
          <p className={classes.upperLeftParragraph}>
            Si no tienes un objetivo en mente y quieres descubrir de qué se
            trata invertir, es aconsejable hacerlo en{" "}
            <strong>productos Líquidos</strong> (que puedas retirar tu dinero) y{" "}
            <strong>de bajo riesgo</strong> (para que reduzcas la posibilidad de
            pérdidas)
          </p>
        </section>
        <div className={classes.step1Container}>
          <p className={classes.rightParragraph}>
            ¿Quieres que te ayudemos a revisar opciones disponibles en el
            mercado?
          </p>
          <div className={classes.buttonsArrayContainer}>
            <div
              onClick={() => onSend()}
              className={classes.buttonContainerRight}
            >
              <div>
                <span className={classes.buttonTitle}>Si</span>
              </div>
              <div>{<ArrowForwardIosIcon />}</div>
            </div>
            <div
              onClick={() => setCreditCategory(-1)}
              className={classes.buttonContainerRightDark}
            >
              <div>
                <span className={classes.buttonTitle}>No</span>
              </div>
              <div>{<ArrowForwardIosIcon />}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default JustInvest;

const list = [
  {
    id: 0,
    name: "Un nuevo crédito",
    onClick: () => {
      navigate("/newCredit");
    },
  },
  {
    id: 1,
    name: "Refinanciar un crédito",
    onClick: () => {},
  },
  {
    id: 2,
    name: "Consolidar un crédito",
    onClick: () => {},
  },
];

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./InvestmentProfile.style";
import { TextInput } from "@components/Inputs";
import { ButtonPrimary } from "@components/Reusables";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { TypeHouseSelector } from "@components/Inputs/SingleSelectors/Services/TypeHouseSelector";
import { HouseStateSelector } from "@components/Inputs/SingleSelectors/Services/HouseStateSelector";
import { ObjectiveSelector } from "@components/Inputs/SingleSelectors/Services/Investments/ObjectiveSelector";
import { TimeSelector } from "@components/Inputs/SingleSelectors/Services/Investments/TimeSelector";
import { InvestmentProfileSelector } from "@components/Inputs/SingleSelectors/Services/Investments/InvestmentProfileSelector";
import { navigate } from "gatsby-link";
import { Route } from "interfaces/routes";
import HeaderServices from "@components/Services/Layout/Header/Header";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";
import goal from "pages/goals";
import { useCurrentUser } from "@apollo";
import { useApolloClient } from "@apollo/client";
import { LoadingContext } from "@context";
import { useForm } from "@hooks";

interface AutomotiveProps {
  className?: string;
  setPathRoute: (value: JSX.Element) => void;
  setNumberStep?: (value: number) => void;
  setFinishSave: (value: any) => void;
  setCreditCategory: (value: number) => void;
}
const InvestmentProfile = ({
  className,
  setPathRoute,
  setNumberStep,
  setFinishSave,
  setCreditCategory,
}: AutomotiveProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();
  const [form] = useForm({});

  const [investmentProfile, setInvestmentProfile] = useState<string>();
  const disabledButton = investmentProfile;

  useEffect(() => {
    setPathRoute(
      <>
        <span className="spaceCredit">
          <ArrowForwardIosIcon />
        </span>
        <span className="spaceCredit">Perfil de inversión</span>
      </>
    );
  }, []);

  const onSend = useCallback(() => {
    form.investmentProfile = {
      quiero: "Analizar opciones de inversión para perfil " + investmentProfile,
    };
    setFinishSave({
      Notification48hs: false,
      Notification24hs: true,
      Notification: false,
    });

    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.investmentProfile;

    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form, investmentProfile]);

  return (
    <>
      <HeaderServices
        title="INVERSIONES"
        onBackClick={() => setCreditCategory(-1)}
        steps={["Perfil de Inversión"]}
      />
      <div className={`${className || ""} ${classes.container}`}>
        <section className={classes.containerList}>
          <div className={classes.selectorContainer}>
            <div className="containerInput">
              <span>Perfil de Inversión</span>{" "}
              <InvestmentProfileSelector
                className="select"
                onChange={(e) => {
                  setInvestmentProfile(e.target.value as string);
                }}
              />
            </div>
          </div>
          <div
            className={classes.InvestmentProfilebutton}
            onClick={() => navigate(Route.toolsSavingAndInvestMission1)}
          >
            <span>Quiero descubrir mi perfil de inversión</span>
          </div>
          <div className={classes.button}>
            <ButtonPrimary
              className={classes.buttonPrimary}
              noDegraded
              text="Enviar"
              onClick={() => onSend()}
              disabled={!disabledButton}
            />
          </div>
        </section>{" "}
      </div>
    </>
  );
};
export default InvestmentProfile;

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./PlanObjective.style";
import { TextInput } from "@components/Inputs";
import { ButtonPrimary } from "@components/Reusables";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { ObjectiveSelector } from "@components/Inputs/SingleSelectors/Services/Investments/ObjectiveSelector";
import { TimeSelector } from "@components/Inputs/SingleSelectors/Services/Investments/TimeSelector";
import { InvestmentProfileSelector } from "@components/Inputs/SingleSelectors/Services/Investments/InvestmentProfileSelector";
import { navigate } from "gatsby-link";
import { Route } from "interfaces/routes";
import HeaderServices from "@components/Services/Layout/Header/Header";
import { String } from "aws-sdk/clients/apigateway";
import { useForm } from "@hooks";
import { useApolloClient } from "@apollo/client";
import { LoadingContext } from "@context";
import { useCurrentUser } from "@apollo";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface AutomotiveProps {
  className?: string;
  setPathRoute: (value: JSX.Element) => void;
  setNumberStep?: (value: number) => void;
  setFinishSave: (value: any) => void;
  setCreditCategory: (value: number) => void;
}
const PlanObjective = ({
  className,
  setPathRoute,
  setNumberStep,
  setFinishSave,
  setCreditCategory,
}: AutomotiveProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();

  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();
  const [form] = useForm({});

  const [goal, setGoal] = useState<string>();
  const [time, setTime] = useState<number>();
  const [timeSelector, setTimeSelector] = useState<string>();
  const [profileInvestment, setProfileInvestment] = useState<string>();

  useEffect(() => {
    setPathRoute(
      <>
        <span className="spaceCredit">
          <ArrowForwardIosIcon />
        </span>
        <span className="spaceCredit">Objetivo</span>
      </>
    );
  }, []);

  const disabledButton = goal && time && timeSelector && profileInvestment;

  const onSend = useCallback(() => {
    form.planAGoal = {
      objetivo: goal,
      tiempo: `${time} ${timeSelector}`,
      perfilDeInversion: profileInvestment,
    };
    setFinishSave({
      Notification48hs: false,
      Notification24hs: true,
      Notification: false,
    });

    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.planAGoal;

    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form, goal, time, timeSelector, profileInvestment]);

  return (
    <>
      <HeaderServices
        title="INVERSIONES"
        onBackClick={() => setCreditCategory(-1)}
        steps={["Objetivo"]}
      />
      <div className={`${className || ""} ${classes.container}`}>
        <section className={classes.containerList}>
          <div>
            <div className="containerInput">
              <span>Objetivo</span>
              <ObjectiveSelector
                className={classes.selector}
                onChange={(e) => {
                  setGoal(e.target.value as string);
                }}
                value={goal}
              />
            </div>
            {isMobile && (
              <div className={classes.row}>
                <div className="containerInput">
                  <span>Tiempo</span>
                  <TextInput
                    className={classes.textInput}
                    onChange={(e) => {
                      setTime(e.target.value as number);
                    }}
                    value={time}
                    name="a"
                    type={"number"}
                    placeholder="Number"
                  />
                </div>
                <div className={classes.timeContainer}>
                  <span>
                    <wbr></wbr>
                  </span>{" "}
                  <TimeSelector
                    className={classes.selectorShort}
                    onChange={(e) => {
                      setTimeSelector(e.target.value as string);
                    }}
                    value={timeSelector}
                  />
                </div>
              </div>
            )}
            {!isMobile && (
              <div className="containerInput">
                <span>Tiempo</span>
                <TextInput
                  className="input"
                  onChange={(e) => {
                    setTime(e.target.value as number);
                  }}
                  value={time}
                  name="a"
                  type={"number"}
                  placeholder="Number"
                />
              </div>
            )}
            {!isMobile && (
              <div className="containerInput">
                <span>
                  <wbr></wbr>
                </span>{" "}
                <TimeSelector
                  className="select"
                  onChange={(e) => {
                    setTimeSelector(e.target.value as string);
                  }}
                  value={timeSelector}
                />
              </div>
            )}

            <div className="containerInput">
              <span>Perfil de Inversión</span>{" "}
              <InvestmentProfileSelector
                className="select"
                onChange={(e) => {
                  setProfileInvestment(e.target.value as string);
                }}
                value={profileInvestment}
              />
            </div>
          </div>
          <div
            className={classes.InvestmentProfilebutton}
            onClick={() => navigate(Route.toolsSavingAndInvestMission1)}
          >
            <span>Quiero descubrir mi perfil de inversión</span>
          </div>
        </section>{" "}
        <div className={classes.button}>
          <ButtonPrimary
            className={classes.buttonPrimary}
            noDegraded
            text="Enviar"
            disabled={!disabledButton}
            onClick={onSend}
          />
        </div>
      </div>
    </>
  );
};
export default PlanObjective;

import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 5rem",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      minHeight: "49rem",
    },
  },
  title: {
    paddingBottom: "1.5rem",
    color: theme.palette.text.secondary,
    fontWeight: 300,
  },
  containerList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "2rem 4rem",
    "& >div": {
      display: "flex",
      gap: "2.5rem",
      flexDirection: "row",
      [theme.breakpoints.down(1240)]: {
        flexDirection: "column",
      },
    },
    "& >div:last-child": {
      justifyContent: "start",
    },
    "& .input": {
      width: "12.5rem",
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down(1240)]: {
        flexDirection: "column",
      },
    },
    "& .select": {
      width: "12rem",
      display: "flex",

      [theme.breakpoints.down(1240)]: {
        flexDirection: "column",
      },
    },
    "& .containerInput": {
      display: "flex",

      flexDirection: "column",

      //      display: "flex",
      /*       flexDirection: "column",
       */
    },
  },
  button: {
    marginTop: "0rem",
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down(1240)]: {
      justifyContent: "center",
    },
  },
  buttonPrimary: {
    width: "18rem",
    marginRight: "3rem",
    marginTop: "2rem",
    [theme.breakpoints.down(1240)]: {
      marginRight: "0rem",
      marginTop: "12rem",
    },
  },
  InvestmentProfilebutton: {
    position: "relative",
    right: "-44rem",
    top: "-1rem",
    [theme.breakpoints.down(1240)]: {
      position: "static",
    },
    "& span": {
      fontFamily: theme.typography.fontFamily,
      fontSize: " 0.75rem",
      color: "#7D7C7C",
      textDecoration: "underline",
      textDecorationColor: "#D3D3D3",
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
  },
  textInput1: {
    display: "flex",
    flexDirection: "column",
  },
  selector: {
    width: "12rem",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
    },
  },
  selectorShort: {},
  timeContainer: {
    display: "flex",
    width: "30%",
    flexDirection: "column",
  },
}));
export default useStyles;

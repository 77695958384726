import useGeneralStyles from '@components/Services/styles';
import ServiceButton from '@components/Services/ServiceButton/ServiceButton';
import React, { useEffect, useState } from 'react';
import useStyles from './Investments.styles';
import { navigate } from 'gatsby';
import PlanObjective from './PlanObjective/PlanObjective';
import InvestmentProfile from './InvestmentProfile/InvestmentProfile';
import HowToInvest from './HowToInvest/HowToInvest';
import JustInvest from './JustInvest/JustInvest';
import HeaderServices from '../Layout/Header/Header';
import { Route } from '@interfaces';

interface creditsProps {
  setCreditCategory: (value: number) => void;
  creditCategory: number;
  setPathRoute: (value: JSX.Element) => void;
  setFinishSave: (value: any) => void;
}

const Investments: React.FC<creditsProps> = ({
  setCreditCategory,
  creditCategory,
  setPathRoute,
  setFinishSave,
}) => {
  useEffect(() => {
    const location = window.location.href.split('#')[1];
    location && location === 'PlanObjective'
      ? setCreditCategory(0)
      : location === 'InvestmentOptions'
      ? setCreditCategory(1)
      : location === 'HowToInvest'
      ? setCreditCategory(2)
      : setCreditCategory(-1);
  }, []);
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [numberStep, setNumberStep] = useState(0);
  const [onBack, setOnBack] = useState(false);

  return creditCategory === -1 ? (
    <>
      <HeaderServices
        title="INVERSIONES"
        onBackClick={() => navigate(Route.services)}
      />
      <section className={classesGeneral.container}>
        <div className={classesGeneral.title}>
          Invertir es muy importante para alcanzar nuestros objetivos y que
          nuestros ahorros no pierdan valor.
        </div>
        <div className={classesGeneral.principalTitle}>
          ¿Qué buscas de inversiones?
        </div>
        <div className={classes.containerItems}>
          {list.map((item) => (
            <ServiceButton
              onClick={() => {
                setCreditCategory(item.id);
                item.onClick;
              }}
              key={item.id}
              name={item.name}
            />
          ))}
        </div>
      </section>
    </>
  ) : creditCategory === 0 ? (
    <>
      {' '}
      <PlanObjective
        setNumberStep={setNumberStep}
        setPathRoute={setPathRoute}
        setFinishSave={setFinishSave}
        setCreditCategory={setCreditCategory}
      />
    </>
  ) : creditCategory === 1 ? (
    <>
      <InvestmentProfile
        setNumberStep={setNumberStep}
        setPathRoute={setPathRoute}
        setFinishSave={setFinishSave}
        setCreditCategory={setCreditCategory}
      />
    </>
  ) : creditCategory === 2 ? (
    <>
      <HowToInvest
        setNumberStep={setNumberStep}
        setPathRoute={setPathRoute}
        creditCategory={creditCategory}
        setCreditCategory={setCreditCategory}
        setFinishSave={setFinishSave}
      />
    </>
  ) : creditCategory === 3 ? (
    <>
      <JustInvest
        setFinishSave={setFinishSave}
        setCreditCategory={setCreditCategory}
      />
    </>
  ) : (
    <>4</>
  );
};
export default Investments;

const list = [
  {
    id: 0,
    name: 'Planificar un objetivo',
    onClick: () => {
      navigate('/newCredit');
    },
  },
  {
    id: 1,
    name: 'Opciones de inversión',
    onClick: () => {},
  },
  {
    id: 2,
    name: 'Cómo hacerlo',
    onClick: () => {},
  },
  /*{
    id: 3,
    name: "Sólo quiero invertir",
    onClick: () => {},
  },*/
];

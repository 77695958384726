import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    marginBottom: "0rem",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 5rem",
    gap: "5rem",
    display: "flex",
    [theme.breakpoints.down(1280)]: {
      width: "100%",
      flexDirection: "column",
      padding: "1.9rem 2.5rem 1rem 2.5rem",
      gap: "0rem",
    },
  },
  step1Container: {
    width: "50%",
    gap: "3rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1280)]: {
      width: "100%",
      gap: "1rem",
    },
  },
  buttonContainer: {
    cursor: "pointer",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 300,
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "17.6rem",
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: 10,
    padding: "0.5rem 1.25rem",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      padding: "0.5rem 1.25rem",
      marginTop: "1.5rem",
    },
    "& span": {
      display: "block",
    },
    "& svg": {
      color: theme.palette.text.disabled,
      width: "1.1rem",
    },
    "& >div": {
      lineHeight: "1.7rem",
    },
  },
  buttonContainerRight: {
    cursor: "pointer",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 300,
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "17.6rem",
    border: `1px solid #00A9C2`,
    borderRadius: 10,
    padding: "0.5rem 1.25rem",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      padding: "0.5rem 1.25rem",
    },
    "& span": {
      display: "block",
    },
    "& svg": {
      color: theme.palette.text.disabled,
      width: "1.1rem",
    },
    "& >div": {
      lineHeight: "1.7rem",
    },
  },
  buttonTitle: {
    fontWeight: "bold",
    display: "block",
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1240)]: {
      fontSize: "0.875rem",
    },
  },
  buttonsArrayContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  leftParragraph: {
    fontFamily: "Montserrat",
    color: "#7D7D7C",
    fontSize: "1rem",
    [theme.breakpoints.down(1240)]: {
      fontSize: "0.875rem",
    },
  },
  rightParragraph: {
    fontFamily: theme.typography.fontFamily,
    color: "#6B6B6B",
    fontSize: "0.875rem",
    fontWeight: 600,
    width: "18rem",
    [theme.breakpoints.down(1240)]: {
      marginTop: "2.5rem",
      width: "auto",
    },
  },
}));
export default useStyles;

import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    marginBottom: "0rem",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "3.75rem 14rem 5rem 14rem",
    gap: "2.5rem",
    display: "flex",
    paddingBottom: "2rem",
    flexDirection: "column",
    [theme.breakpoints.down(1280)]: {
      width: "100%",
      padding: "2rem 2.5rem 0rem 2.5rem",
      gap: "1.5rem",
      minHeight: "44rem",
      paddingBottom: "0rem",
    },
  },
  parragraph: {
    fontFamily: "Montserrat",
    color: "#7D7D7C",
    fontSize: "1rem",
    [theme.breakpoints.down(1240)]: {
      fontSize: "0.875rem",
    },
  },
  primaryButton: {
    width: "17.8rem",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
    },
  },
  buttons: {
    display: "flex",
    gap: "2.5rem",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      gap: "1.5rem",
      justifyContent: "center",
      marginTop: "16rem",
      marginBottom: "1.5rem",
    },
  },
}));
export default useStyles;

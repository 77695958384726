import useGeneralStyles from "@components/Services/styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import React, { useCallback, useContext, useState } from "react";
import useStyles from "./ETF.style";
import { navigate } from "gatsby";
import { useForm } from "@hooks";
import { Route } from "interfaces/routes";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { PERSON_ICON } from "images/AWS/productTour";
import { ButtonPrimary } from "@components/Reusables/ButtonPrimary";
import { ButtonOutlined } from "@components/Reusables/ButtonOutlined";
import HeaderServices from "@components/Services/Layout/Header/Header";
import { useCurrentUser } from "@apollo";
import { useApolloClient } from "@apollo/client";
import { LoadingContext } from "@context";
import { useMediaQuery } from "@material-ui/core";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface creditsProps {
  setCreditCategory: (value: number) => void;
  creditCategory: number;
  setPathRoute: (value: JSX.Element) => void;
  setFinishSave: (value: any) => void;
  setStep: (value: number) => void;
}

const ETF: React.FC<creditsProps> = ({
  setCreditCategory,
  creditCategory,
  setFinishSave,
  setPathRoute,
  setStep,
}) => {
  const classes = useStyles();
  const [form] = useForm();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();

  const onSend = useCallback(() => {
    form.investmentProfile = {
      quiero: "Analizar opciones de inversión para ETF ",
    };
    setFinishSave({
      Notification48hs: false,
      Notification24hs: true,
      Notification: false,
    });

    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.investmentProfile;

    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form]);
  return (
    <>
      <HeaderServices
        title="INVERSIONES"
        onBackClick={() => setStep(-1)}
        steps={["Cómo invertir", "ETF"]}
      />
      <div className={classes.container}>
        <p className={classes.parragraph}>
          Los <strong>ETF</strong> son instrumentos que permiten invertir en{" "}
          <strong>gran cantidad de activos</strong> (por ejemplo empresas) y que
          suelen <strong>replicar el comportamiento de un índice</strong> (como
          la Bolsa de Estados Unidos).
        </p>
        <div>
          <p className={classes.parragraph}>
            Estos tienen las siguientes características:
          </p>
          <p className={classes.parragraph}>
            · Son <strong>más baratos</strong> que un Fondo mutuo
          </p>
          <p className={classes.parragraph}>
            · <strong>No tienen un plazo definido</strong>{" "}
          </p>
          <p className={classes.parragraph}>
            · Puedes retirar el dinero entre <strong>2 y 10 días</strong>{" "}
          </p>
          <p className={classes.parragraph}>
            · Son <strong>más riesgosos</strong>{" "}
          </p>
        </div>
        <p className={classes.parragraph}>
          Podemos analizar dónde existen las mejores opciones de ETF.
        </p>
        <div className={classes.buttons}>
          <ButtonPrimary
            className={classes.primaryButton}
            text="Analizar esta opción"
            noDegraded
            onClick={() => onSend()}
          />
          <ButtonOutlined
            text="Ver otra forma de Inversión"
            className={classes.primaryButton}
            onClick={() => setStep(-1)}
          />
        </div>
      </div>
    </>
  );
};
export default ETF;

const list = [
  {
    id: 0,
    name: "Un nuevo crédito",
    onClick: () => {
      navigate("/newCredit");
    },
  },
  {
    id: 1,
    name: "Refinanciar un crédito",
    onClick: () => {},
  },
  {
    id: 2,
    name: "Consolidar un crédito",
    onClick: () => {},
  },
];

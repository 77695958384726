import useGeneralStyles from "@components/Services/styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import React, { useContext, useState } from "react";
import useStyles from "./HowToInvest.style";
import { navigate } from "gatsby";
import { useForm } from "@hooks";
import { Route } from "interfaces/routes";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { PERSON_ICON } from "images/AWS/productTour";
import FixedTermDeposit from "./FixedTermDeposit/FixedTermDeposit";
import MutualFunds from "./MutualFunds/MutualFunds";
import ETF from "./ETF/ETF";
import Shares from "./Shares/Shares";
import Departments from "./Departments/Departments";
import HeaderServices from "@components/Services/Layout/Header/Header";

interface creditsProps {
  setCreditCategory: (value: number) => void;
  creditCategory: number;
  setPathRoute: (value: JSX.Element) => void;
  setFinishSave: (value: any) => void;
}

const HowToInvest: React.FC<creditsProps> = ({
  setCreditCategory,
  creditCategory,
  setFinishSave,
  setPathRoute,
}) => {
  const classes = useStyles();
  const [step, setStep] = useState(-1);
  const [form] = useForm();

  return step === -1 ? (
    <>
      <HeaderServices
        title="INVERSIONES"
        onBackClick={() => setCreditCategory(-1)}
        steps={["Cómo invertir"]}
      />
      <div className={classes.container}>
        <section className={classes.step1Container}>
          <p className={classes.leftParragraph}>
            Para comenzar a invertir, lo más importante es{" "}
            <strong>crear un hábito</strong> y hacerlo en{" "}
            <strong>productos que conoces.</strong>
          </p>
          <p className={classes.leftParragraph}>
            Antes de partirn debes tener claro tu{" "}
            <strong>perfil de inversión.</strong> Si aún no sabes bien cuál es,
            puedes usar nuestro test.
          </p>

          <div
            onClick={() => navigate(Route.toolsSavingAndInvestMission1)}
            className={classes.buttonContainer}
          >
            <img src={PERSON_ICON} alt="" />
            <div>
              <span className={classes.buttonTitle}>Hacer el test</span>
            </div>
            <div>{<ArrowForwardIosIcon />}</div>
          </div>
        </section>
        <div className={classes.step1Container}>
          <p className={classes.rightParragraph}>
            Existen diferentes instrumentos para invertir, ¿cuál te interesa
            conocer?
          </p>
          <div className={classes.buttonsArrayContainer}>
            <div
              onClick={() => {
                setStep(0);
                form.instrumentsToInvest = "Depósito a plazo";
              }}
              className={classes.buttonContainerRight}
            >
              <div>
                <span className={classes.buttonTitle}>Depósitos a plazo</span>
              </div>
              <div>{<ArrowForwardIosIcon />}</div>
            </div>
            <div
              onClick={() => {
                setStep(1);
              }}
              className={classes.buttonContainerRight}
            >
              <div>
                <span className={classes.buttonTitle}>Fondos mutuos</span>
              </div>
              <div>{<ArrowForwardIosIcon />}</div>
            </div>
            <div
              onClick={() => setStep(2)}
              className={classes.buttonContainerRight}
            >
              <div>
                <span className={classes.buttonTitle}>ETF</span>
              </div>
              <div>{<ArrowForwardIosIcon />}</div>
            </div>
            <div
              onClick={() => setStep(3)}
              className={classes.buttonContainerRight}
            >
              <div>
                <span className={classes.buttonTitle}>Acciones</span>
              </div>
              <div>{<ArrowForwardIosIcon />}</div>
            </div>
            <div
              onClick={() => setStep(4)}
              className={classes.buttonContainerRight}
            >
              <div>
                <span className={classes.buttonTitle}>Departamentos</span>
              </div>
              <div>{<ArrowForwardIosIcon />}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : step === 0 ? (
    <div>
      <FixedTermDeposit
        setFinishSave={setFinishSave}
        setCreditCategory={setCreditCategory}
        setStep={setStep}
      />
    </div>
  ) : step === 1 ? (
    <div>
      <MutualFunds
        setFinishSave={setFinishSave}
        setCreditCategory={setCreditCategory}
        setStep={setStep}
      />
    </div>
  ) : step === 2 ? (
    <div>
      <ETF
        setFinishSave={setFinishSave}
        setCreditCategory={setCreditCategory}
        setStep={setStep}
      />
    </div>
  ) : step === 3 ? (
    <div>
      <Shares
        setFinishSave={setFinishSave}
        setCreditCategory={setCreditCategory}
        setStep={setStep}
      />
    </div>
  ) : (
    <div>
      <Departments
        setFinishSave={setFinishSave}
        setCreditCategory={setCreditCategory}
        setStep={setStep}
      />
    </div>
  );
};
export default HowToInvest;

const list = [
  {
    id: 0,
    name: "Un nuevo crédito",
    onClick: () => {
      navigate("/newCredit");
    },
  },
  {
    id: 1,
    name: "Refinanciar un crédito",
    onClick: () => {},
  },
  {
    id: 2,
    name: "Consolidar un crédito",
    onClick: () => {},
  },
];
